import { Component } from 'vue-property-decorator'

import { AUTH_LOGOUT, inject, SHOW_MESSAGE_DIALOG } from 'booksprout-app'
import { AuthService } from 'booksprout-app'
import BaseSignUpMixin from '../mixins/baseSignUpMixin'

@Component
export default class ForgotPasswordComponent extends BaseSignUpMixin {
  emailAddress: string = ''
  newPassword: string = ''

  @inject('AuthService')
  authService!: AuthService

  get completing () {
    return this.$route.params.token !== void 0
  }

  async requestPassword () {
    if (this.isAuthenticated) {
      await this.authService.logout()
      await this.$store.dispatch(AUTH_LOGOUT)
    }

    return this.authService.resetPassword(this.emailAddress).then(() => {
      void this.$store.dispatch(SHOW_MESSAGE_DIALOG, {
        message: this.$tc('modules.forgotPassword.labels.checkYourEmail')
      })
    }).catch((e) => {
      void this.$store.dispatch(SHOW_MESSAGE_DIALOG, {
        error: true,
        message: e?.message
          ? this.$tc('system.errors.somethingWentWrongWithParams', void 0, {
            message: e.message
          })
          : this.$tc('modules.forgotPassword.labels.somethingWentWrong')
      })
    }).finally(() => void this.$router.push('/login'))
  }

  async resetPassword () {
    if (this.isAuthenticated) {
      await this.authService.logout()
      await this.$store.dispatch(AUTH_LOGOUT)
    }

    const token: string = this.$route.params.token
    return this.authService.completeResetPassword(token, this.newPassword).then(() => {
      void this.$store.dispatch(SHOW_MESSAGE_DIALOG, {
        message: this.$tc('modules.forgotPassword.labels.passwordResetComplete')
      })
    }).catch((e: any) => {
      void this.$store.dispatch(SHOW_MESSAGE_DIALOG, {
        error: true,
        message: e.message
      })
    }).finally(() => void this.$router.push('/login'))
  }
}
